import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import SupportLeftNav from "../../../components/TwoColumnLeftNav/SupportLeftNav";
import LocalizedLink from "../../../components/localizedLink";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { documents, emissivity } = useTranslations();

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>{documents}</h2>
					<div className="content-container">
						<ul className="documents-ul flex wrap">
							<li className="documents-li">
								<a href={`/support/documents/bimetal/`} target={"_blank"} rel="noreferrer">
									バイメタル式温度計（在庫規格品）図面
								</a>
							</li>
							<li className="documents-li">
								<a href={`/support/documents/bimetal-order/`} target={"_blank"} rel="noreferrer">
									バイメタル式温度計（注文製作品）図面
								</a>
							</li>
							<li className="documents-li">
								<a
									href={`/support/documents/remote-measurement-type/`} target={"_blank"} rel="noreferrer"
								>
									隔測式温度計（在庫規格品）図面
								</a>
							</li>
							<li className="documents-li">
								<a
									href={`/support/documents/remote-measurement-type-order1/`} target={"_blank"} rel="noreferrer"
								>
									隔測式温度計（注文製作品：防滴型）図面
								</a>
							</li>
							<li className="documents-li">
								<a
									href={`/support/documents/remote-measurement-type-order2/`} target={"_blank"} rel="noreferrer"
								>
									隔測式温度計（注文製作品：防水型）図面
								</a>
							</li>
							<li className="documents-li">
								<a
									href={`/support/documents/remote-measurement-type-order3/`} target={"_blank"} rel="noreferrer"
								>
									隔測式温度計（注文製作品：オイル入）図面
								</a>
							</li>
							<li className="documents-li">
								<a
									href={`/support/documents/remote-measurement-type-order4/`} target={"_blank"} rel="noreferrer"
								>
									隔測式温度計（注文製作品：接点付）図面
								</a>
							</li>


							<li className="documents-li ">
								<a href={`/support/documents/emissivity/`} target={"_blank"} rel="noreferrer">
									{emissivity}
								</a>
							</li>

							<li className="documents-li">
								<a
									href={`/support/documents/ph-calibration-solution/`}
									target={"_blank"}
									rel="noreferrer"
								>
									pH標準液　安全データシート
								</a>
							</li>

							<li className="documents-li">
								<a
									href={`/support/documents/about-temperature/temp01/`}
									target={"_blank"}
									rel="noreferrer"
								>
									温度ってなんだろう
								</a>
							</li>
							<li className="documents-li">
								<a
								    href={`/support/documents/catchall/`}
								    target={"_blank"}
									rel="noreferrer"
								>
									弊社製品を輸出されるお客様へのお知らせ：キャッチオール規制
								</a>
							</li>
							<li className="documents-li">
								<a
								    href={`/support/documents/haccp/`}
								    target={"_blank"}
									rel="noreferrer"
								>
									HACCP支援製品特集
								</a>
							</li>
							<li className="documents-li">
								<a
								    href={`/support/documents/against-infection/`}
								    target={"_blank"}
									rel="noreferrer"
								>
									感染症対策製品特集
								</a>
							</li>
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
